import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../../components/affix/AutoAffix";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import FormContatti from "../../../components/form-contatti";

import Square from "../../../svg/square-det.svg";
import SquareImgDx from "../../../svg/square-img-dx.svg";
import SquareImgSx from "../../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Liferay Digital Experience Platform",
    description: "Le migliori soluzioni per realizzare esperienze digitali",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Liferay", link: "/competence-center/liferay/" },
  { title: "Liferay DXP" },
];

const LiferayDXP = ({ data, location }) => {
  const { intro, mercato, accoppiato, befe, channel, beapife } = data;

  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Liferay Digital Experience Platform`} description={`Tecnologia Human Driven`} />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Nel 2003 Ariadne realizzò la sua prima soluzione proprietaria di Content Managment
                System. In quegli anni se ci avessero chiesto perché acquistare un CMS, avremmo
                risposto: i CMS nascono per consentire agli utenti di pubblicare contenuti su un
                sito web pur richiedendo poca o nessuna conoscenza del codice di programmazione.
              </p>
              <p>Oggi come risponderemmo alla stessa domanda?</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "i-cms-sono-ancora-fondamentali-per-gestire-il-business-digitale-cosa-e-cambiato-rispetto-al-passato",
                        "come-si-presenta-oggi-il-mercato-dei-cms-quali-sono-i-criteri-di-scelta",
                        "1-cms-accoppiato-monolitico-o-tradizionale",
                        "2-cms-headless",
                        "3-cms-disaccopiati",
                        "il-passaggio-da-cms-a-dxp-il-caso-liferay",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#i-cms-sono-ancora-fondamentali-per-gestire-il-business-digitale-cosa-e-cambiato-rispetto-al-passato`}
                          to={`i-cms-sono-ancora-fondamentali-per-gestire-il-business-digitale-cosa-e-cambiato-rispetto-al-passato`}
                          className="anchor-nav__link"
                          title="I CMS sono ancora fondamentali per gestire il business digitale? Cosa è cambiato rispetto al passato?"
                        >
                          I CMS sono ancora fondamentali per gestire il business digitale? Cosa è
                          cambiato rispetto al passato?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#come-si-presenta-oggi-il-mercato-dei-cms-quali-sono-i-criteri-di-scelta`}
                          to={`come-si-presenta-oggi-il-mercato-dei-cms-quali-sono-i-criteri-di-scelta`}
                          className="anchor-nav__link"
                          title="Come si presenta oggi il mercato dei CMS? Quali sono i criteri di scelta?"
                        >
                          Come si presenta oggi il mercato dei CMS? Quali sono i criteri di scelta?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#1-cms-accoppiato-monolitico-o-tradizionale`}
                          to={`1-cms-accoppiato-monolitico-o-tradizionale`}
                          className="anchor-nav__link"
                          title="1. CMS accoppiato (monolitico o tradizionale)"
                        >
                          1. CMS accoppiato (monolitico o tradizionale)
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#2-cms-headless`}
                          to={`2-cms-headless`}
                          className="anchor-nav__link"
                          title="2. CMS Headless"
                        >
                          2. CMS Headless
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#3-cms-disaccopiati`}
                          to={`3-cms-disaccopiati`}
                          className="anchor-nav__link"
                          title="3. CMS disaccopiati"
                        >
                          3. CMS disaccopiati
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#il-passaggio-da-cms-a-dxp-il-caso-liferay`}
                          to={`il-passaggio-da-cms-a-dxp-il-caso-liferay`}
                          className="anchor-nav__link"
                          title="Il passaggio da CMS a DXP: il caso Liferay"
                        >
                          Il passaggio da CMS a DXP: il caso Liferay
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div
                  id="i-cms-sono-ancora-fondamentali-per-gestire-il-business-digitale-cosa-e-cambiato-rispetto-al-passato"
                  className="anchor"
                >
                  <h2 className="anchor-text__title">
                    I CMS sono ancora fondamentali per gestire il business digitale? Cosa è cambiato
                    rispetto al passato?
                  </h2>
                  <p>
                    Secondo noi la risposta è sicuramente affermativa, chiamiamoli WCM o DXP, la
                    gestione di contenuti o più in generale delle risorse erogate tramite canali
                    digitali rimane un aspetto fondamentale del business di oggi.
                  </p>
                  <p>
                    Rispetto al passato, qui la parola chiave è "canale digitale", i clienti non
                    interagiscono più solo tramite i siti, ma spesso l'esigenza è quella di
                    multicanalità che si traduce in "scrivo il contenuto e lo distribuisco nei
                    diversi canali con il minimo sforzo possibile".
                  </p>
                  <p>
                    I CMS emergenti sono quindi quelli in grado di integrarsi in maniera efficiente
                    con le tecnologie in continua evoluzione del marketing digitale.
                  </p>
                </div>
                <div
                  id="come-si-presenta-oggi-il-mercato-dei-cms-quali-sono-i-criteri-di-scelta"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(mercato)}
                    alt="Image mercato"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Come si presenta oggi il mercato dei CMS? Quali sono i criteri di scelta?
                  </h2>
                  <p>
                    Se un tempo l’aspetto critico della scelta era quello funzionale (ovvero la
                    presenza o meno di determinate funzionalità del CMS), con il crescere del
                    mercato l’offerta si è livellata e la maggior parte delle funzionalità
                    necessarie sono considerate commodity.
                    <br />
                    Ad oggi il mercato dei CMS conta migliaia di migliaia di competitors tanto che
                    Gartner nel 2020 ha deciso di non pubblicare più l’annuale report Magic Quadrant
                    for Web Content Management con la motivazione che il mercato "ha raggiunto la
                    sua maturità con prodotti sempre più omogenei", inglobando i WCM nel più ampio
                    concetto di Digital Experience Platforms{" "}
                  </p>
                  <p>
                    Per rispondere alla domanda e rimanendo in ambito tecnologico, secondo noi il
                    principale elemento di valutazione riguarda la scelta della giusta architettura
                    software per la propria realtà. Una corretta valutazione permetterà non solo di
                    raggiungere gli aspetti di business ma darà soddisfazione all'intero team:
                    editor, sviluppatori, designer e professionisti del marketing.{" "}
                  </p>
                  <p>Lo scenario attuale presente tre architetture ben definite: </p>
                  <ol>
                    <li>CMS accoppiati detti anche monolitici o tradizionali. </li>
                    <li>CMS headless </li>
                    <li>CMS disaccoppiati </li>
                  </ol>
                  <p>Vediamo in sintesi pro e contro di queste soluzioni </p>
                </div>
                <div id="1-cms-accoppiato-monolitico-o-tradizionale" className="anchor">
                  <GatsbyImage
                    image={getImage(accoppiato)}
                    alt="Image cms accoppiato"
                    className="anchor-text__img w-100"
                  />
                  <h2 className="anchor-text__title">
                    1. CMS accoppiato (monolitico o tradizionale)
                  </h2>
                  <p>
                    In questi CMS il back-end (redazione) e il front-end (sito) sono serviti dallo
                    stesso sistema. Le piattaforme di blog, come WordPress, Drupal, sono esempi di
                    architettura CMS tradizionale.
                  </p>
                  <GatsbyImage
                    image={getImage(befe)}
                    alt="Image backend-frontend"
                    className="anchor-text__img"
                  />
                  <p>
                    Ad esempio, sulla piattaforma WordPress si può cambiare l’aspetto del sito anche
                    senza avere particolari conoscenze di web design grazie all’aiuto del template
                    engine. Ciò significa anche che nella creazione si è vincolati alle direttive
                    del sistema (ad esempio su WP siamo legati al linguaggio PHP). Ad oggi molti CMS
                    monolitici si dichiarano headless in quando hanno introdotto nel tempo un layer
                    di disaccoppiamento basato su servizi web (API REST, GRAPHQL) tuttavia il
                    modello architetturale è diverso e in scenari complessi non possono garantire lo
                    stesso livello di flessibilità.
                  </p>
                  <p>PRO: </p>
                  <ul>
                    <li>
                      Customizzazione del front end senza conoscenze tecniche grazie alla
                      disponibilità di un vasto mercato di temi/template.
                    </li>
                    <li>Customizzazione del front end tramite plugin e widget</li>
                    <li>
                      Semplice e ideale per comunicazioni monocanali che non necessitano di
                      particolari performance.
                    </li>
                  </ul>
                  <p>CONTRO: </p>
                  <ul>
                    <li>
                      In front-end si è vincolati al linguaggio di programmazione imposto dal CMS
                    </li>
                    <li>
                      Costi elevati per la manutenzione (in particolar modo per gli aspetti di
                      sicurezza)
                    </li>
                    <li>Scalabilità e performance potrebbe essere punti critici</li>
                  </ul>
                  <p>
                    In questa categoria i CMS più popolari sono: Wordpress, Drupal, Joomla, Wix. Il
                    64% dei siti che usa un CMS è realizzato con Wordpress (
                    <a
                      href="https://w3techs.com/technologies/overview/content_management"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://w3techs.com/technologies/overview/content_management
                    </a>
                    )
                  </p>
                </div>
                <div id="2-cms-headless" className="anchor">
                  <h2 className="anchor-text__title">2. CMS Headless</h2>
                  <p>
                    In questa architettura la parte di front-end è completamente assente. Un CMS
                    headless viene progettato esclusivamente pensando al contenuto, questo implica
                    che i punti di forza di questa soluzione dovrebbero essere:
                  </p>
                  <ul>
                    <li>
                      L’esperienza utente nella progettazione, nell’editing e nella distribuzione
                      dei contenuti.
                    </li>
                    <li>
                      L’interfaccia programmatica di accesso ai dati (REST e GRAPHQL). Per sua
                      natura queste architetture si prestano molto ad essere erogata in modalità
                      SaaS, per questo motivo rappresentano un mercato in forte espansione e con
                      ampia scelta.
                    </li>
                  </ul>
                  <GatsbyImage
                    image={getImage(channel)}
                    alt="Image channel"
                    className="anchor-text__img"
                  />
                  <p>PRO: </p>
                  <ul>
                    <li>Performance nel content delivery</li>
                    <li>Provides complete control over how and where your content appears</li>
                    <li>Secure third-party integrations</li>
                    <li>Allows developers to use their favorite tools and frameworks</li>
                    <li>Future-proof (integrates easily with new technology and innovations)</li>
                  </ul>
                  <p>CONTRO:</p>
                  <ul>
                    <li>No built-in presentation functionality</li>
                    <li>
                      Difficult to see a live preview without significant development assistance
                    </li>
                  </ul>
                  <p>
                    La scelta di un CMS Healdess rappresenta quindi solo una parte della scelta
                    della propria architettura, in quanto da sola non permette di avere nessun tipo
                    di presentazione. Tra le possibili soluzioni complementari è doveroso
                    evidenziare le architetture JAMstack e di conseguenza il settore dei generatori
                    di siti statici (Gatsby, 11ty, Hugo, Jekyll, Next.js, ecc..)
                    <br />
                    In questa categoria i CMS più popolari sono: Contentfful, Kentico Kontent,
                    Prismic, Directus, Butter CMS, GraphCMS.
                  </p>
                </div>
                <div id="3-cms-disaccopiati" className="anchor">
                  <h2 className="anchor-text__title">3. CMS disaccopiati</h2>
                  <p>
                    Un CMS disaccoppiato separa, o disaccoppia, la gestione back-end e front-end di
                    un sito web in due diversi sistemi: uno per la gestione dei contenuti (back-end)
                    e uno per la loro presentazione (front-end). In un CMS disaccoppiato, questi due
                    sistemi sono entrambi presenti, fisicamente separati e dialogano tramite API
                    (solitamente tramite un pattern architetturale publish/subscribe).{" "}
                  </p>
                  <GatsbyImage
                    image={getImage(beapife)}
                    alt="Image backend api frontend"
                    className="anchor-text__img"
                  />
                  <p>
                    Molti considerano questa soluzione il meglio di entrambi i mondi: presenta un
                    front-end di default come le piattaforme CMS tradizionale, ma ha la flessibilità
                    di un'implementazione CMS headless, garantendo sicurezza e scalabilità Tuttavia,
                    gli aspetti da non sottovalutare sono:
                  </p>
                  <ol>
                    <li>
                      Sono architetture piuttosto complesse la cui gestione necessità un costo di
                      acquisizione know how non indifferente
                    </li>
                    <li>
                      Nel front-end di default si è comunque vincolati ad una determinata
                      tecnologia, quindi nel caso si volesse abbandonare ci si porta appresso una
                      complessità non necessaria a costi superiori a quelli di un servizio puramente
                      headless.
                    </li>
                    <li>
                      Si prestano poco a soluzione SaaS Per questi motivi i CMS disaccoppiati sono
                      solitamente rivolti ad un mercato enterprise, hanno costi elevati e il mercato
                      presenta scarsa scelta. Per questo alcuni vendor hanno deciso di proporre la
                      loro soluzione anche in modalità solo headless e ovviamente in SaaS.{" "}
                    </li>
                  </ol>
                  <p>PRO: </p>
                  <ul>
                    <li>
                      Fast and flexible content delivery with a specified delivery environment
                    </li>
                    <li>Easy and secure third-party integrations</li>
                    <li>The best of both worlds in a CMS (structured BE &amp; flexible FE)</li>
                    <li>Future-proof (integrates easily with new technology and innovations) </li>
                  </ul>
                  <p>CONTRO:</p>
                  <ul>
                    <li>Maggiore complessità nella configurazione, deploy e manutenzione</li>
                  </ul>
                  <p>In questa categoria i CMS più popolari sono: Magnolia, Crafter CMS.</p>
                </div>
                <div id="il-passaggio-da-cms-a-dxp-il-caso-liferay" className="anchor">
                  <h2 className="anchor-text__title">Il passaggio da CMS a DXP: il caso Liferay</h2>
                  <p>
                    Può sembrare che le DXP siano solo nuove versioni di CMS, portali e prodotti
                    commerciali, a seguito di un processo di rebranding, ma i vendor di DXP usano un
                    approccio diverso alle funzioni tradizionali di tali prodotti, per soddisfare le
                    nuove aspettative dei clienti e trarre il massimo beneficio dalle nuove
                    tecnologie.
                  </p>
                  <p>
                    Al momento di scegliere una DXP, le aziende dovrebbero valutare se sono pronte
                    ad una trasformazione profonda delle loro operazioni commerciali, o se
                    concentrarsi principalmente sul marketing e sulla brand awareness, poiché queste
                    priorità tendono a riflettersi sui diversi punti di forza dei vendor di DXP.
                  </p>
                  <p>
                    In una presentazione di Forrester intitolata "Al di là delle apparenze:
                    strategie di collaborazione per una vera trasformazione digitale", l'analista
                    Liz Herbert osserva che "la vera trasformazione digitale tocca sia l'esperienza
                    che il nucleo delle operazioni". Questa risposta alle esigenze dell'utente può
                    essere raggiunta in diversi modi, dalla personalizzazione dell'esperienza, a
                    flussi di lavoro configurabili più avanzati, alla completa modulazione dei
                    servizi aziendali che possono essere rapidamente riassemblati in nuovi modi.
                  </p>
                  <p>
                    Tuttavia, non tutte le aziende sono preparate per questo livello di cambiamento
                    e nel frattempo potrebbero iniziare a segmentare il pubblico al fine di
                    individuare offerte personalizzate. Alcuni vendor, in particolare quelli che
                    sono in possesso di sistemi di gestione dei contenuti, possono farlo senza
                    troppi problemi.
                  </p>
                  <p>
                    Idealmente, la piattaforma dovrà possedere sia delle funzionalità operative sia
                    di marketing, in modo che, anche se iniziate con una campagna di marketing
                    digitale, quando sarete pronti per una trasformazione operativa, non dovrete
                    passare attraverso un secondo processo di selezione del vendor.
                  </p>
                  <p>
                    C'è da dire comunque che non esiste un solo modo per raggiungere tali obiettivi.
                    L'utilizzo di qualsiasi tipo di DXP, in combinazione con un'architettura per
                    l'integrazione e servizi modulari, può darvi gli stessi risultati.
                  </p>
                  <p>
                    A causa delle crescenti aspettative del cliente, le imprese stanno facendo leva
                    sulle customer experience personalizzate per trarne un vantaggio competitivo. Le
                    funzionalità incluse nella <strong>Liferay Digital Experience Platform</strong>{" "}
                    pongono in risalto i vantaggi, in termini di business, delle caratteristiche di
                    una DXP. Un framework completo risulta fondamentale per creare esperienze online
                    tagliate su misura e contestualizzate rispetto ai bisogni reali del cliente.
                  </p>
                  <p>
                    La gestione del sito Web oggi è un processo altamente collaborativo, che spesso
                    coinvolge parti interessate da più ruoli, come sviluppatori, designer,
                    amministratori di siti e operatori di marketing. Per soddisfare queste esigenze,
                    è fondamentale che le piattaforme web forniscano applicazioni ampie e robuste
                    che un dipendente in qualsiasi ruolo possa imparare rapidamente a utilizzare.
                    Inoltre, i team di sviluppo dovrebbero avere il potere di aprire e
                    personalizzare le loro piattaforme, dando loro la libertà di creare veri
                    vantaggi competitivi unici per la loro attività. Una caratteristica chiave della
                    gestione dei contenuti in Liferay DXP è il disaccoppiamento tra la creazione e
                    la gestione dei contenuti dalla consegna dei contenuti. Questa separazione
                    consente all'organizzazione di avere la flessibilità di scegliere la propria
                    strategia di gestione dei contenuti, se utilizzare Liferay DXP come un CMS
                    headless, un CMS tradizionale o un ibrido dei due.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default LiferayDXP;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/liferay-dxp-intro.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    mercato: file(relativePath: { eq: "pages/liferay-dxp-mercato.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    accoppiato: file(relativePath: { eq: "pages/liferay-dxp-accoppiato.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    befe: file(relativePath: { eq: "pages/liferay-dxp-Be-FE.png" }) {
      childImageSharp {
        gatsbyImageData(width: 348, layout: CONSTRAINED)
      }
    }
    channel: file(relativePath: { eq: "pages/liferay-dxp-channel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 464, layout: CONSTRAINED)
      }
    }
    beapife: file(relativePath: { eq: "pages/liferay-dxp-be-api-fe.png" }) {
      childImageSharp {
        gatsbyImageData(width: 464, layout: CONSTRAINED)
      }
    }
  }
`;
